/* FONT PATH
 * -------------------------- */
@font-face {
    font-family: "Material Design Icons";
    font-style: normal;
    font-weight: 400;
    src: url("https://cdn.jsdelivr.net/npm/bootstrap-material-design-icons@2.2.0/fonts/MaterialIcons-Regular.eot?v=2.2.1");
    src: url("https://cdn.jsdelivr.net/npm/bootstrap-material-design-icons@2.2.0/fonts/MaterialIcons-Regular.eot?v=2.2.1#iefix") format("embedded-opentype"), url("https://cdn.jsdelivr.net/npm/bootstrap-material-design-icons@2.2.0/fonts/MaterialIcons-Regular.woff2?v=2.2.1") format("woff2"), url("https://cdn.jsdelivr.net/npm/bootstrap-material-design-icons@2.2.0/fonts/MaterialIcons-Regular.woff?v=2.2.1") format("woff"), url("https://cdn.jsdelivr.net/npm/bootstrap-material-design-icons@2.2.0/fonts/MaterialIcons-Regular.ttf?v=2.2.1") format("truetype"), url("https://cdn.jsdelivr.net/npm/bootstrap-material-design-icons@2.2.0/fonts/MaterialIcons-Regular.svg?v=2.2.1#materialiconsregular") format("svg"); }

html,
body {
    height: 100%;
    font-family: "Nunito",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

.wrap {
    min-height: 100%;
    height: auto;
    margin: 0 auto -60px;
    padding: 0 0 60px;
}

.wrap > .container {
    padding: 70px 20px 20px;
}

.footer {
    height: 60px;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    padding-top: 20px;
}

.jumbotron {
    text-align: center;
    background-color: transparent;
}

.jumbotron .btn {
    font-size: 21px;
    padding: 14px 24px;
}

.not-set {
    color: #c55;
    font-style: italic;
}

/* add sorting icons to gridview sort links */
a.asc:after, a.desc:after {
    /*position: relative;*/
    /*top: 1px;*/
    /*display: inline-block;*/
    /*font-family: 'Glyphicons Halflings';*/
    /*font-style: normal;*/
    /*font-weight: normal;*/
    /*line-height: 1;*/
    font: normal normal normal 14px/1 "Material Design Icons";
    /*font-size: inherit;*/
    display: inline-block;
    text-transform: none;
    letter-spacing: normal;
    /*line-height: 1;*/
    position: relative;
    word-wrap: normal;
    top: 1px;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
    width: 1em;
    height: 1em;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for IE. */
    font-feature-settings: 'liga';
    /*padding-left: 5px;*/
    font-size: 1.33333em;
    line-height: 0.75em;
    vertical-align: -30%;
}

a.asc:after {
    /*content: !*"\e113"*! "\e151";*/
    content: "\e5c5";
}

a.desc:after {
    /*content: !*"\e114"*! "\e152";*/
    content: "\e5c7";
}

/*.sort-numerical a.asc:after {*/
/*    content: "\e153";*/
/*}*/

/*.sort-numerical a.desc:after {*/
/*    content: "\e154";*/
/*}*/

/*.sort-ordinal a.asc:after {*/
/*    content: "\e155";*/
/*}*/

/*.sort-ordinal a.desc:after {*/
/*    content: "\e156";*/
/*}*/

.grid-view th {
    white-space: nowrap;
}

.hint-block {
    display: block;
    margin-top: 5px;
    color: #999;
}

.error-summary {
    color: #a94442;
    background: #fdf7f7;
    border-left: 3px solid #eed3d7;
    padding: 10px 20px;
    margin: 0 0 15px 0;
}

/* align the logout "link" (button in form) of the navbar */
.nav li > form > button.logout {
    padding: 15px;
    border: none;
}

@media(max-width:767px) {
    .nav li > form > button.logout {
        display:block;
        text-align: left;
        width: 100%;
        padding: 10px 15px;
    }
}

.nav > li > form > button.logout:focus,
.nav > li > form > button.logout:hover {
    text-decoration: none;
}

.nav > li > form > button.logout:focus {
    outline: none;
}

.error-404 {
    font-weight: 700;
    font-size: 6rem;
    position: relative;
    text-align: center;
    display: inline-block;
    margin-left: 50%;
    transform: translate(-50%, 0);
}

@-webkit-keyframes noise-anim {
    0% {
        clip: rect(32px,9999px,16px,0)
    }

    5% {
        clip: rect(5px,9999px,24px,0)
    }

    10% {
        clip: rect(77px,9999px,87px,0)
    }

    15% {
        clip: rect(91px,9999px,95px,0)
    }

    20% {
        clip: rect(74px,9999px,9px,0)
    }

    25% {
        clip: rect(37px,9999px,32px,0)
    }

    30% {
        clip: rect(56px,9999px,27px,0)
    }

    35% {
        clip: rect(35px,9999px,33px,0)
    }

    40% {
        clip: rect(89px,9999px,6px,0)
    }

    45% {
        clip: rect(81px,9999px,77px,0)
    }

    50% {
        clip: rect(64px,9999px,69px,0)
    }

    55% {
        clip: rect(12px,9999px,11px,0)
    }

    60% {
        clip: rect(59px,9999px,11px,0)
    }

    65% {
        clip: rect(69px,9999px,59px,0)
    }

    70% {
        clip: rect(74px,9999px,65px,0)
    }

    75% {
        clip: rect(56px,9999px,79px,0)
    }

    80% {
        clip: rect(80px,9999px,64px,0)
    }

    85% {
        clip: rect(87px,9999px,29px,0)
    }

    90% {
        clip: rect(16px,9999px,21px,0)
    }

    95% {
        clip: rect(69px,9999px,43px,0)
    }

    100% {
        clip: rect(75px,9999px,63px,0)
    }
}

@keyframes noise-anim {
    0% {
        clip: rect(32px,9999px,16px,0)
    }

    5% {
        clip: rect(5px,9999px,24px,0)
    }

    10% {
        clip: rect(77px,9999px,87px,0)
    }

    15% {
        clip: rect(91px,9999px,95px,0)
    }

    20% {
        clip: rect(74px,9999px,9px,0)
    }

    25% {
        clip: rect(37px,9999px,32px,0)
    }

    30% {
        clip: rect(56px,9999px,27px,0)
    }

    35% {
        clip: rect(35px,9999px,33px,0)
    }

    40% {
        clip: rect(89px,9999px,6px,0)
    }

    45% {
        clip: rect(81px,9999px,77px,0)
    }

    50% {
        clip: rect(64px,9999px,69px,0)
    }

    55% {
        clip: rect(12px,9999px,11px,0)
    }

    60% {
        clip: rect(59px,9999px,11px,0)
    }

    65% {
        clip: rect(69px,9999px,59px,0)
    }

    70% {
        clip: rect(74px,9999px,65px,0)
    }

    75% {
        clip: rect(56px,9999px,79px,0)
    }

    80% {
        clip: rect(80px,9999px,64px,0)
    }

    85% {
        clip: rect(87px,9999px,29px,0)
    }

    90% {
        clip: rect(16px,9999px,21px,0)
    }

    95% {
        clip: rect(69px,9999px,43px,0)
    }

    100% {
        clip: rect(75px,9999px,63px,0)
    }
}

.error-404:after {
    content: attr(data-text);
    position: absolute;
    left: 2px;
    text-shadow: -1px 0 #e74a3b;
    top: 0;
    color: #5a5c69;
    background: #f8f9fc;
    overflow: hidden;
    clip: rect(0,900px,0,0);
    animation: noise-anim 2s infinite linear alternate-reverse
}

@-webkit-keyframes noise-anim-2 {
    0% {
        clip: rect(12px,9999px,52px,0)
    }

    5% {
        clip: rect(42px,9999px,39px,0)
    }

    10% {
        clip: rect(64px,9999px,36px,0)
    }

    15% {
        clip: rect(52px,9999px,15px,0)
    }

    20% {
        clip: rect(79px,9999px,7px,0)
    }

    25% {
        clip: rect(17px,9999px,41px,0)
    }

    30% {
        clip: rect(15px,9999px,20px,0)
    }

    35% {
        clip: rect(62px,9999px,87px,0)
    }

    40% {
        clip: rect(94px,9999px,11px,0)
    }

    45% {
        clip: rect(49px,9999px,10px,0)
    }

    50% {
        clip: rect(82px,9999px,4px,0)
    }

    55% {
        clip: rect(70px,9999px,100px,0)
    }

    60% {
        clip: rect(62px,9999px,23px,0)
    }

    65% {
        clip: rect(51px,9999px,56px,0)
    }

    70% {
        clip: rect(41px,9999px,24px,0)
    }

    75% {
        clip: rect(6px,9999px,85px,0)
    }

    80% {
        clip: rect(96px,9999px,58px,0)
    }

    85% {
        clip: rect(16px,9999px,24px,0)
    }

    90% {
        clip: rect(40px,9999px,31px,0)
    }

    95% {
        clip: rect(91px,9999px,34px,0)
    }

    100% {
        clip: rect(87px,9999px,26px,0)
    }
}

@keyframes noise-anim-2 {
    0% {
        clip: rect(12px,9999px,52px,0)
    }

    5% {
        clip: rect(42px,9999px,39px,0)
    }

    10% {
        clip: rect(64px,9999px,36px,0)
    }

    15% {
        clip: rect(52px,9999px,15px,0)
    }

    20% {
        clip: rect(79px,9999px,7px,0)
    }

    25% {
        clip: rect(17px,9999px,41px,0)
    }

    30% {
        clip: rect(15px,9999px,20px,0)
    }

    35% {
        clip: rect(62px,9999px,87px,0)
    }

    40% {
        clip: rect(94px,9999px,11px,0)
    }

    45% {
        clip: rect(49px,9999px,10px,0)
    }

    50% {
        clip: rect(82px,9999px,4px,0)
    }

    55% {
        clip: rect(70px,9999px,100px,0)
    }

    60% {
        clip: rect(62px,9999px,23px,0)
    }

    65% {
        clip: rect(51px,9999px,56px,0)
    }

    70% {
        clip: rect(41px,9999px,24px,0)
    }

    75% {
        clip: rect(6px,9999px,85px,0)
    }

    80% {
        clip: rect(96px,9999px,58px,0)
    }

    85% {
        clip: rect(16px,9999px,24px,0)
    }

    90% {
        clip: rect(40px,9999px,31px,0)
    }

    95% {
        clip: rect(91px,9999px,34px,0)
    }

    100% {
        clip: rect(87px,9999px,26px,0)
    }
}

.error-404:before {
    content: attr(data-text);
    position: absolute;
    left: -2px;
    text-shadow: 1px 0 #4e73df;
    top: 0;
    color: #5a5c69;
    background: #f8f9fc;
    overflow: hidden;
    clip: rect(0,900px,0,0);
    animation: noise-anim-2 3s infinite linear alternate-reverse
}